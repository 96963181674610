
import { defineComponent, ref, reactive, onMounted } from 'vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import downloadFile from '@/utils/claim/downloadFile';
import { postDetilRejectToSICCO } from '@/API/checking/paymentPreparation';
type Key = ColumnProps['key'];

export default defineComponent({
  props: {
    checkVisible: {
      type: Boolean,
      required: true,
      default: false
    },
    checkDataSource: {
      type: Array,
      required: true,
      default: () => []
    },
    checkData: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    }
  },
  emit: ['close', 'submit'],
  setup(props, { emit }) {
    const data = (props as any).checkData;

    const colums = [
      {
        title: 'NO.',
        dataIndex: 'no',
        width: 80,
        align: 'left'
      },
      {
        title: 'Program Code',
        dataIndex: 'programCode',
        width: 150,
        align: 'left'
      },
      {
        title: 'Program Category',
        dataIndex: 'programCategory',
        width: 150,
        align: 'left'
      },
      {
        title: 'Vin',
        dataIndex: 'vin',
        width: 170,
        align: 'left'
      },
      {
        title: 'Retail Eligibility in OASIS',
        dataIndex: 'retailEligibility',
        width: 180,
        align: 'left'
      },
      {
        title: 'Retail Check Error Type',
        dataIndex: 'retailCheckErrorType',
        width: 180,
        align: 'left'
      },
      {
        title: 'Retail Date',
        dataIndex: 'retailCheckDate',
        width: 150,
        align: 'left'
      },
      {
        title: 'Information Updated in SOP',
        dataIndex: 'mismatchDate',
        width: 180,
        align: 'left'
      }
    ];

    const rowSelection = reactive<{
      selectedRowKeys: Key[];
      selectedRowData: any[];
      onChange: Function;
    }>({
      onChange: (selectedRowKeys: Key[], selectedRows: any[]) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: []
    });

    const close = () => {
      emit('close');
    };

    const disabledReject = () => {
      //console.log(rowSelection.selectedRowData.length);
      if (rowSelection.selectedRowData.length === 0) {
        return true;
      } else {
        return false;
      }
    };

    const handleExport = () => {
      const param = {
        url: '/claimapi/paymentPreparation/detail/submitToControlling/check/export',
        method: 'post',
        params: data
      };
      downloadFile(param, 'application/vnd-excel; char-set=UTF-8');
    };

    const handleSubmit = () => {
      emit('submit');
    };

    const handleReject = () => {
      const ids: any[] = [];
      if (rowSelection.selectedRowData.length > 0) {
        rowSelection.selectedRowData.forEach((item: any) => {
          ids.push(item.claimAppId);
        });
      } else {
        props.checkDataSource.forEach((item: any) => {
          ids.push(item.claimAppId);
        });
      }
      data.ids = ids;

      postDetilRejectToSICCO(data).then((res) => {
        emit('close');
      });
    };

    return { colums, close, rowSelection, disabledReject, handleExport, data, handleSubmit, handleReject };
  }
});
