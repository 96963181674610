
import { computed, defineComponent, PropType, reactive, ref, nextTick, watch } from 'vue';
import { message } from 'ant-design-vue';
import cleanEmptyEleArray from '@/utils/rv/cleanEmptyElement';

export default defineComponent({
  props: {
    inputLabel: {
      type: String,
      require: true,
      default: ''
    },
    inputVals: {
      type: Array as PropType<any[]>,
      require: true,
      default: () => []
    },
    batchQueryTitle: {
      type: String,
      require: true,
      default: ''
    },
    batchQueryListTitle: {
      type: String,
      require: true,
      default: ''
    }
  },
  emits: ['change-input-vals'],
  setup(props, { emit }) {
    const vals = computed(() => {
      return props.inputVals;
    });

    // 批量复制
    const batchQuery = reactive({
      batchQueryTitle: '',
      batchQueryModal: false,
      batchQueryListTitle: ''
    });
    const textContentNo = ref<string>('');
    const textNoArray = ref<string[]>([]);
    const input = ref();
    const batchPlaceholder = ref(['', '', '', '', '', '']);
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ['', '', '', '', '', ''];
      }
    };

    // 批量查询按钮事件
    const batchQueryEvent = () => {
      batchQuery.batchQueryModal = true;
      batchQuery.batchQueryTitle = props.batchQueryTitle;
      batchQuery.batchQueryListTitle = props.batchQueryListTitle;
      setBatchPlaceholder();
      const dom = document.getElementsByClassName('list-content')[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      batchQuery.batchQueryModal = false;
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error('请从Excel中粘贴到文本框');
        textContentNo.value = '';
        return;
      }
      textNoArray.value = textNoArray.value.concat(
        textContentNo.value
          .replace(/\r|\n|\s/g, ',')
          .split(',')
          .filter((item) => item)
      );
      const dom = document.getElementsByClassName('list-content')[0];
      textContentNo.value = '';
      emit('change-input-vals', textNoArray.value);
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 清除批量查询
    watch(vals, (newVal: any) => {
      if (newVal.length === 0) {
        textNoArray.value = [];
      }
    });
    const clearBatchQuery = () => {
      emit('change-input-vals', []);
      textNoArray.value = [];
      setBatchPlaceholder();
      input.value.focus();
    };
    return {
      vals,
      batchQuery,
      textContentNo,
      textNoArray,
      input,
      batchPlaceholder,
      closeBatchQueryModal,
      batchQueryEvent,
      textChangEvent,
      clearBatchQuery
      // changeInputVal
    };
  }
});
